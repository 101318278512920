<template>
	<div>
		<div class="certificate">
			<div class="ct_div">
				<div class="ct_di_title">{{$t('pcbBatch.certificate.title')}}</div>
				<div class="ct_di_text">{{$t('pcbBatch.certificate.text')}}</div>
				<div class="ct_di_labelDiv">
					<div class="ct_di_ld_text" v-for="(item,index) in $t('pcbBatch.certificate.label')" :key="index">{{item}}</div>
				</div>
				<!-- <el-image style="width: 100%;margin-top: 40px;" :src="require('@/assets/img/why-choose1@img.png')" :fit="'fill'"></el-image> -->
				<div class="ct_di_ld_content">
					<div class="ct_di_ld_ct_div">
						<el-image class="ct_di_ld_ct_di_img" :src="require('@/assets/img/certificate/esc.png')" :fit="'fill'"></el-image>
					</div>
					<div class="ct_di_ld_ct_div">
						<el-image class="ct_di_ld_ct_di_img" :src="require('@/assets/img/certificate/henlv1.png')" :fit="'fill'"></el-image>
					</div>
					<div class="ct_di_ld_ct_div">
						<el-image class="ct_di_ld_ct_di_img" :src="require('@/assets/img/certificate/ISO.png')" :fit="'fill'"></el-image>
					</div>
					<div class="ct_di_ld_ct_div">
						<el-image class="ct_di_ld_ct_di_img" :src="require('@/assets/img/certificate/nkt.png')" :fit="'fill'"></el-image>
					</div>
					<div class="ct_di_ld_ct_div">
						<el-image class="ct_di_ld_ct_di_img" :src="require('@/assets/img/certificate/qlmos.png')" :fit="'fill'"></el-image>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				lable:['"专精特新"企业','高新技术企业','IATF 16949汽车质量管理体系认证'],
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.certificate{
			padding: 40px 200px;
			background: #333333;
			
			.ct_div{
				text-align: center;
				
				.ct_di_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.ct_di_text{
					font-size: 18px;
					color: #fff;
					line-height: 28px;
				}
				
				.ct_di_labelDiv{
					display: flex;
					justify-content: center;
					
					.ct_di_ld_text{
						color: red;
						padding: 2px 20px;
						border: 1px solid red;
						border-radius: 40px;
						margin: 20px 10px;
					}
				}
				
				.ct_di_ld_content{
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					
					.ct_di_ld_ct_div{
						width: 16vw;
						padding: 0 10px;
						
						.ct_di_ld_ct_di_img{
							width: 100%;
							margin-top: 40px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.certificate{
			padding: 40px 200px;
			background: #333333;
			
			.ct_div{
				text-align: center;
				
				.ct_di_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.ct_di_text{
					font-size: 18px;
					color: #fff;
					line-height: 28px;
				}
				
				.ct_di_labelDiv{
					display: flex;
					justify-content: center;
					
					.ct_di_ld_text{
						color: red;
						padding: 2px 20px;
						border: 1px solid red;
						border-radius: 40px;
						margin: 20px 10px;
					}
				}
				
				.ct_di_ld_content{
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					
					.ct_di_ld_ct_div{
						width: 16vw;
						padding: 0 10px;
						
						.ct_di_ld_ct_di_img{
							width: 100%;
							margin-top: 40px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.certificate{
			padding: 40px 140px;
			background: #333333;
			
			.ct_div{
				text-align: center;
				
				.ct_di_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.ct_di_text{
					font-size: 18px;
					color: #fff;
					line-height: 28px;
				}
				
				.ct_di_labelDiv{
					display: flex;
					justify-content: center;
					
					.ct_di_ld_text{
						color: red;
						padding: 2px 20px;
						border: 1px solid red;
						border-radius: 40px;
						margin: 20px 10px;
					}
				}
				
				.ct_di_ld_content{
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					
					.ct_di_ld_ct_div{
						width: 16vw;
						padding: 0 10px;
						
						.ct_di_ld_ct_di_img{
							width: 100%;
							margin-top: 40px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.certificate{
			padding: 40px 30px;
			background: #333333;
			
			.ct_div{
				text-align: center;
				
				.ct_di_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.ct_di_text{
					font-size: 18px;
					color: #fff;
					line-height: 28px;
				}
				
				.ct_di_labelDiv{
					display: flex;
					justify-content: center;
					
					.ct_di_ld_text{
						color: red;
						padding: 2px 20px;
						border: 1px solid red;
						border-radius: 40px;
						margin: 20px 10px;
					}
				}
				
				.ct_di_ld_content{
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					
					.ct_di_ld_ct_div{
						width: 16vw;
						padding: 0 10px;
						
						.ct_di_ld_ct_di_img{
							width: 100%;
							margin-top: 40px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.certificate{
			padding: 40px 30px;
			background: #333333;
			
			.ct_div{
				text-align: center;
				
				.ct_di_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.ct_di_text{
					font-size: 18px;
					color: #fff;
					line-height: 28px;
				}
				
				.ct_di_labelDiv{
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					
					.ct_di_ld_text{
						color: red;
						padding: 2px 20px;
						border: 1px solid red;
						border-radius: 40px;
						margin: 20px 10px;
					}
				}
				
				.ct_di_ld_content{
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					
					.ct_di_ld_ct_div{
						width: 100%;
						padding: 0 10px;
						
						.ct_di_ld_ct_di_img{
							width: 100%;
							margin-top: 40px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.certificate{
			padding: 40px 30px;
			background: #333333;
			
			.ct_div{
				text-align: center;
				
				.ct_di_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.ct_di_text{
					font-size: 18px;
					color: #fff;
					line-height: 28px;
				}
				
				.ct_di_labelDiv{
					display: flex;
					justify-content: center;
					flex-wrap: wrap;
					
					.ct_di_ld_text{
						color: red;
						padding: 2px 20px;
						border: 1px solid red;
						border-radius: 40px;
						margin: 20px 10px;
					}
				}
				
				.ct_di_ld_content{
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					
					.ct_di_ld_ct_div{
						width: 100%;
						padding: 0 10px;
						
						.ct_di_ld_ct_di_img{
							width: 100%;
							margin-top: 40px;
						}
					}
				}
			}
		}
	}
	
</style>