<template>
	<div class="chooseUs">
		<div class="cu_contentTop">
			<div class="cu_ct_content">
				<div class="cu_ct_ct_title">{{$t('pcbBatch.chooseUs.title')}}</div>
				<div class="cu_ct_ct_text">{{$t('pcbBatch.chooseUs.text')}}</div>
				
				<el-image class="cu_ct_ct_img" :src="$t('pcbBatch.chooseUs.img')" :fit="'fill'"></el-image>
			</div>
		</div>
		
		
		<div class="cu_contentCenter">
			<div class="cu_cc_left">
				<div class="cu_cc_le_left">
					<div class="cu_cc_le_le_titleDiv">
						<a class="cu_cc_le_le_td_title">{{$t('pcbBatch.chooseUs.content1.title')}}</a>
					</div>
					<div class="cu_cc_le_le_text1">{{$t('pcbBatch.chooseUs.content1.text1')}}</div>
					<div class="cu_cc_le_le_text2">{{$t('pcbBatch.chooseUs.content1.text2')}}</div>
				</div>
				<div class="cu_cc_le_right">
					<el-image class="cu_cc_le_ri_img" :src="require('@/assets/img/price.png')" :fit="'fill'"></el-image>
				</div>
			</div>
			<div class="cu_cc_right">
				<div class="cu_cc_ri_left">
					<div class="cu_cc_ri_le_titleDiv">
						<a class="cu_cc_ri_le_td_title">{{$t('pcbBatch.chooseUs.content2.title')}}</a>
					</div>
					<div class="cu_cc_ri_le_text1">{{$t('pcbBatch.chooseUs.content2.text1')}}</div>
					<div class="cu_cc_ri_le_text2">{{$t('pcbBatch.chooseUs.content2.text2')}}</div>
				</div>
				<div class="cu_cc_ri_right">
					<el-image class="cu_cc_ri_ri_img" :src="require('@/assets/img/quality.png')" :fit="'fill'"></el-image>
				</div>
			</div>
		</div>
		
		<div class="cu_contentBottom" v-if="windowWidth>=1200">
			<div class="cu_cb_titleDiv">
				<div class="cu_cb_td_title">{{$t('pcbBatch.chooseUs.content3.title')}}</div>
				<div class="cu_cb_td_text">{{$t('pcbBatch.chooseUs.content3.text')}}</div>
			</div>
			
			<div class="cu_cb_contentDiv">
				<el-image class="cu_cb_cd_img" :src="$t('pcbBatch.chooseUs.content3.img')" :fit="'fill'"></el-image>
			</div>
		</div>
		
		<div class="cu_contentBottom2" v-if="windowWidth<=1199">
			<div class="cu_cb_titleDiv">
				<div class="cu_cb_td_title">{{$t('pcbBatch.chooseUs.content3.title')}}</div>
				<div class="cu_cb_td_text">{{$t('pcbBatch.chooseUs.content3.text')}}</div>
			</div>
			
			<div class="cu_cb_contentDiv2">
				<el-image class="cu_cb_cd_img" :src="$t('pcbBatch.chooseUs.content3.img')" :fit="'fill'"></el-image>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.chooseUs{
			
			.cu_contentTop{
				padding: 40px 200px 0 200px;
				
				.cu_ct_content{
					text-align: center;
					
					.cu_ct_ct_title{
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.cu_ct_ct_text{
						font-size: 18px;
						color: #626262;
						line-height: 28px;
					}
					
					.cu_ct_ct_img{
						width: 100%;
						margin-top: 40px;
					}
				}
			}
			
			.cu_contentCenter{
				background: #333333;
				width: 100%;
				display: flex;
				align-items: center;
				
				.cu_cc_left{
					display: flex;
					width: 50%;
					padding: 40px 40px 40px 240px;
					background: linear-gradient(to right, #333333, #3d3d3d);
					
					.cu_cc_le_left{
						width: 60%;
						
						.cu_cc_le_le_titleDiv{
							padding-bottom: 20px;
							
							.cu_cc_le_le_td_title{
								font-size: 1.4vw;
								color: #fff;
								border-bottom: 1px solid #fff;
								padding: 10px 0;
							}
						}
						
						.cu_cc_le_le_text1{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
						
						.cu_cc_le_le_text2{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
					}
					
					.cu_cc_le_right{
						width: 40%;
						text-align: right;
						
						.cu_cc_le_ri_img{
							width: 5vw;
							padding-top: 4vw;
						}
					}
				}
				
				.cu_cc_right{
					display: flex;
					width: 50%;
					padding: 40px 240px 40px 60px;
					background: linear-gradient(to right, #333333, #3d3d3d);
					
					.cu_cc_ri_left{
						width: 60%;
						
						.cu_cc_ri_le_titleDiv{
							padding-bottom: 20px;
							
							.cu_cc_ri_le_td_title{
								font-size: 1.4vw;
								color: #fff;
								border-bottom: 1px solid #fff;
								padding: 10px 0;
							}
						}
						
						.cu_cc_ri_le_text1{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
						
						.cu_cc_ri_le_text2{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
					}
					
					.cu_cc_ri_right{
						width: 40%;
						text-align: right;
						
						.cu_cc_ri_ri_img{
							width: 5vw;
							padding-top: 4vw;
						}
					}
				}
			}
			
			.cu_contentBottom{
				background: #fff;
				padding: 40px 200px;
				
				.cu_cb_titleDiv{
					text-align: center;
					
					.cu_cb_td_title{
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.cu_cb_td_text{
						font-size: 18px;
						color: #626262;
						line-height: 28px;
					}
				}
				
				.cu_cb_contentDiv{
					display: flex;
					padding-bottom: 40px;
					
					.cu_cb_cd_img{
						width: 100%;
						padding-top: 4vw;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.chooseUs{
			
			.cu_contentTop{
				padding: 40px 200px 0 200px;
				
				.cu_ct_content{
					text-align: center;
					
					.cu_ct_ct_title{
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.cu_ct_ct_text{
						font-size: 18px;
						color: #626262;
						line-height: 28px;
					}
					
					.cu_ct_ct_img{
						width: 100%;
						margin-top: 40px;
					}
				}
			}
			
			.cu_contentCenter{
				background: #333333;
				width: 100%;
				display: flex;
				align-items: center;
				
				.cu_cc_left{
					display: flex;
					width: 50%;
					padding: 40px 40px 40px 240px;
					background: linear-gradient(to right, #333333, #3d3d3d);
					
					.cu_cc_le_left{
						width: 60%;
						
						.cu_cc_le_le_titleDiv{
							padding-bottom: 20px;
							
							.cu_cc_le_le_td_title{
								font-size: 1.4vw;
								color: #fff;
								border-bottom: 1px solid #fff;
								padding: 10px 0;
							}
						}
						
						.cu_cc_le_le_text1{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
						
						.cu_cc_le_le_text2{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
					}
					
					.cu_cc_le_right{
						width: 40%;
						text-align: right;
						
						.cu_cc_le_ri_img{
							width: 5vw;
							padding-top: 4vw;
						}
					}
				}
				
				.cu_cc_right{
					display: flex;
					width: 50%;
					padding: 40px 240px 40px 60px;
					background: linear-gradient(to right, #333333, #3d3d3d);
					
					.cu_cc_ri_left{
						width: 60%;
						
						.cu_cc_ri_le_titleDiv{
							padding-bottom: 20px;
							
							.cu_cc_ri_le_td_title{
								font-size: 1.4vw;
								color: #fff;
								border-bottom: 1px solid #fff;
								padding: 10px 0;
							}
						}
						
						.cu_cc_ri_le_text1{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
						
						.cu_cc_ri_le_text2{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
					}
					
					.cu_cc_ri_right{
						width: 40%;
						text-align: right;
						
						.cu_cc_ri_ri_img{
							width: 5vw;
							padding-top: 4vw;
						}
					}
				}
			}
			
			.cu_contentBottom{
				background: #fff;
				padding: 40px 200px;
				
				.cu_cb_titleDiv{
					text-align: center;
					
					.cu_cb_td_title{
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.cu_cb_td_text{
						font-size: 18px;
						color: #626262;
						line-height: 28px;
					}
				}
				
				.cu_cb_contentDiv{
					display: flex;
					padding-bottom: 40px;
					
					.cu_cb_cd_img{
						width: 100%;
						padding-top: 4vw;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.chooseUs{
			
			.cu_contentTop{
				padding: 40px 140px 0 140px;
				
				.cu_ct_content{
					text-align: center;
					
					.cu_ct_ct_title{
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.cu_ct_ct_text{
						font-size: 18px;
						color: #626262;
						line-height: 28px;
					}
					
					.cu_ct_ct_img{
						width: 100%;
						margin-top: 40px;
					}
				}
			}
			
			.cu_contentCenter{
				background: #333333;
				width: 100%;
				display: flex;
				align-items: center;
				
				.cu_cc_left{
					display: flex;
					width: 50%;
					padding: 40px 40px 40px 180px;
					background: linear-gradient(to right, #333333, #3d3d3d);
					
					.cu_cc_le_left{
						width: 60%;
						
						.cu_cc_le_le_titleDiv{
							padding-bottom: 20px;
							
							.cu_cc_le_le_td_title{
								font-size: 1.4vw;
								color: #fff;
								border-bottom: 1px solid #fff;
								padding: 10px 0;
							}
						}
						
						.cu_cc_le_le_text1{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
						
						.cu_cc_le_le_text2{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
					}
					
					.cu_cc_le_right{
						width: 40%;
						text-align: right;
						
						.cu_cc_le_ri_img{
							width: 5vw;
							padding-top: 4vw;
						}
					}
				}
				
				.cu_cc_right{
					display: flex;
					width: 50%;
					padding: 40px 180px 40px 60px;
					background: linear-gradient(to right, #333333, #3d3d3d);
					
					.cu_cc_ri_left{
						width: 60%;
						
						.cu_cc_ri_le_titleDiv{
							padding-bottom: 20px;
							
							.cu_cc_ri_le_td_title{
								font-size: 1.4vw;
								color: #fff;
								border-bottom: 1px solid #fff;
								padding: 10px 0;
							}
						}
						
						.cu_cc_ri_le_text1{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
						
						.cu_cc_ri_le_text2{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
					}
					
					.cu_cc_ri_right{
						width: 40%;
						text-align: right;
						
						.cu_cc_ri_ri_img{
							width: 5vw;
							padding-top: 4vw;
						}
					}
				}
			}
			
			.cu_contentBottom{
				background: #fff;
				padding: 40px 140px;
				
				.cu_cb_titleDiv{
					text-align: center;
					
					.cu_cb_td_title{
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.cu_cb_td_text{
						font-size: 18px;
						color: #626262;
						line-height: 28px;
					}
				}
				
				.cu_cb_contentDiv{
					display: flex;
					padding-bottom: 40px;
					
					.cu_cb_cd_img{
						width: 100%;
						padding-top: 4vw;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.chooseUs{
			
			.cu_contentTop{
				padding: 40px 30px 0 30px;
				
				.cu_ct_content{
					text-align: center;
					
					.cu_ct_ct_title{
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.cu_ct_ct_text{
						font-size: 18px;
						color: #626262;
						line-height: 28px;
					}
					
					.cu_ct_ct_img{
						width: 100%;
						margin-top: 40px;
					}
				}
			}
			
			.cu_contentCenter{
				background: #333333;
				width: 100%;
				display: flex;
				align-items: center;
				
				.cu_cc_left{
					display: flex;
					width: 50%;
					padding: 40px 40px 40px 70px;
					background: linear-gradient(to right, #333333, #3d3d3d);
					
					.cu_cc_le_left{
						width: 60%;
						
						.cu_cc_le_le_titleDiv{
							padding-bottom: 20px;
							
							.cu_cc_le_le_td_title{
								font-size: 1.4vw;
								color: #fff;
								border-bottom: 1px solid #fff;
								padding: 10px 0;
							}
						}
						
						.cu_cc_le_le_text1{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
						
						.cu_cc_le_le_text2{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
					}
					
					.cu_cc_le_right{
						width: 40%;
						text-align: right;
						
						.cu_cc_le_ri_img{
							width: 5vw;
							padding-top: 4vw;
						}
					}
				}
				
				.cu_cc_right{
					display: flex;
					width: 50%;
					padding: 40px 70px 40px 60px;
					background: linear-gradient(to right, #333333, #3d3d3d);
					
					.cu_cc_ri_left{
						width: 60%;
						
						.cu_cc_ri_le_titleDiv{
							padding-bottom: 20px;
							
							.cu_cc_ri_le_td_title{
								font-size: 1.4vw;
								color: #fff;
								border-bottom: 1px solid #fff;
								padding: 10px 0;
							}
						}
						
						.cu_cc_ri_le_text1{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
						
						.cu_cc_ri_le_text2{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
					}
					
					.cu_cc_ri_right{
						width: 40%;
						text-align: right;
						
						.cu_cc_ri_ri_img{
							width: 5vw;
							padding-top: 4vw;
						}
					}
				}
			}
			
			.cu_contentBottom{
				background: #fff;
				padding: 40px 30px;
				
				.cu_cb_titleDiv{
					text-align: center;
					
					.cu_cb_td_title{
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.cu_cb_td_text{
						font-size: 18px;
						color: #626262;
						line-height: 28px;
					}
				}
				
				.cu_cb_contentDiv{
					display: flex;
					
					.cu_cb_cd_img{
						width: 100%;
						padding-top: 4vw;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.chooseUs{
			
			.cu_contentTop{
				padding: 40px 30px 0 30px;
				
				.cu_ct_content{
					text-align: center;
					
					.cu_ct_ct_title{
						font-size: 21px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.cu_ct_ct_text{
						font-size: 16px;
						color: #626262;
						line-height: 28px;
					}
					
					.cu_ct_ct_img{
						width: 100%;
						margin-top: 40px;
					}
				}
			}
			
			.cu_contentCenter{
				background: #333333;
				width: 100%;
				display: flex;
				align-items: center;
				
				.cu_cc_left{
					display: flex;
					width: 50%;
					padding: 50px 40px 50px 70px;
					background: linear-gradient(to right, #333333, #3d3d3d);
					
					.cu_cc_le_left{
						width: 60%;
						
						.cu_cc_le_le_titleDiv{
							padding-bottom: 20px;
							
							.cu_cc_le_le_td_title{
								font-size: 1.4vw;
								color: #fff;
								border-bottom: 1px solid #fff;
								padding: 10px 0;
							}
						}
						
						.cu_cc_le_le_text1{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
						
						.cu_cc_le_le_text2{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
					}
					
					.cu_cc_le_right{
						width: 40%;
						text-align: right;
						
						.cu_cc_le_ri_img{
							width: 5vw;
							padding-top: 4vw;
						}
					}
				}
				
				.cu_cc_right{
					display: flex;
					width: 50%;
					padding: 40px 70px 40px 60px;
					background: linear-gradient(to right, #333333, #3d3d3d);
					
					.cu_cc_ri_left{
						width: 60%;
						
						.cu_cc_ri_le_titleDiv{
							padding-bottom: 20px;
							
							.cu_cc_ri_le_td_title{
								font-size: 1.4vw;
								color: #fff;
								border-bottom: 1px solid #fff;
								padding: 10px 0;
							}
						}
						
						.cu_cc_ri_le_text1{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
						
						.cu_cc_ri_le_text2{
							font-size: 1vw;
							color: #fff;
							padding-top: 0.8vw;
						}
					}
					
					.cu_cc_ri_right{
						width: 40%;
						text-align: right;
						
						.cu_cc_ri_ri_img{
							width: 5vw;
							padding-top: 4vw;
						}
					}
				}
			}
			
			.cu_contentBottom2{
				background: #fff;
				padding: 40px 30px;
				
				.cu_cb_titleDiv{
					text-align: center;
					
					.cu_cb_td_title{
						font-size: 21px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.cu_cb_td_text{
						font-size: 16px;
						color: #626262;
						line-height: 28px;
					}
				}
				
				.cu_cb_contentDiv2{
					display: flex;
					
					.cu_cb_cd_img{
						width: 100%;
						padding-top: 4vw;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.chooseUs{
			
			.cu_contentTop{
				padding: 40px 30px 0 30px;
				
				.cu_ct_content{
					text-align: center;
					
					.cu_ct_ct_title{
						font-size: 21px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.cu_ct_ct_text{
						font-size: 16px;
						color: #626262;
						line-height: 28px;
					}
					
					.cu_ct_ct_img{
						width: 100%;
						margin-top: 40px;
					}
				}
			}
			
			.cu_contentCenter{
				background: #333333;
				width: 100%;
				display: flex;
				align-items: center;
				
				.cu_cc_left{
					display: flex;
					width: 50%;
					padding: 50px 20px 50px 30px;
					background: linear-gradient(to right, #333333, #3d3d3d);
					
					.cu_cc_le_left{
						width: 70%;
						
						.cu_cc_le_le_titleDiv{
							padding-bottom: 20px;
							
							.cu_cc_le_le_td_title{
								font-size: 16px;
								color: #fff;
								border-bottom: 1px solid #fff;
								padding: 10px 0;
							}
						}
						
						.cu_cc_le_le_text1{
							font-size: 12px;
							color: #fff;
							padding-top: 0.8vw;
						}
						
						.cu_cc_le_le_text2{
							font-size: 12px;
							color: #fff;
							padding-top: 0.8vw;
						}
					}
					
					.cu_cc_le_right{
						width: 30%;
						text-align: right;
						
						.cu_cc_le_ri_img{
							width: 5vw;
							padding-top: 4vw;
						}
					}
				}
				
				.cu_cc_right{
					display: flex;
					width: 50%;
					padding: 40px 30px 40px 20px;
					background: linear-gradient(to right, #333333, #3d3d3d);
					
					.cu_cc_ri_left{
						width: 70%;
						
						.cu_cc_ri_le_titleDiv{
							padding-bottom: 20px;
							
							.cu_cc_ri_le_td_title{
								font-size: 16px;
								color: #fff;
								border-bottom: 1px solid #fff;
								padding: 10px 0;
							}
						}
						
						.cu_cc_ri_le_text1{
							font-size: 10px;
							color: #fff;
							padding-top: 0.8vw;
						}
						
						.cu_cc_ri_le_text2{
							font-size: 10px;
							color: #fff;
							padding-top: 0.8vw;
						}
					}
					
					.cu_cc_ri_right{
						width: 30%;
						text-align: right;
						
						.cu_cc_ri_ri_img{
							width: 5vw;
							padding-top: 4vw;
						}
					}
				}
			}
			
			.cu_contentBottom2{
				background: #fff;
				padding: 40px 30px;
				
				.cu_cb_titleDiv{
					text-align: center;
					
					.cu_cb_td_title{
						font-size: 21px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.cu_cb_td_text{
						font-size: 16px;
						color: #626262;
						line-height: 28px;
					}
				}
				
				.cu_cb_contentDiv2{
					display: flex;
					
					.cu_cb_cd_img{
						width: 100%;
						padding-top: 4vw;
					}
				}
			}
		}
	}
</style>