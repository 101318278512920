<template>
	<div>
		<top :windowWidth="windowWidth" :windowHeight="windowHeight"></top>
		<right v-if="windowWidth>=1200"></right>
		<bulk-juhui :windowWidth="windowWidth" :windowHeight="windowHeight"></bulk-juhui>
		<pcb-both-have :windowWidth="windowWidth" :windowHeight="windowHeight"></pcb-both-have>
		<choose-us :windowWidth="windowWidth" :windowHeight="windowHeight"></choose-us>
		<certificate :windowWidth="windowWidth" :windowHeight="windowHeight"></certificate>
		<about-us :windowWidth="windowWidth" :windowHeight="windowHeight"></about-us>
		<bottom v-if="windowWidth>=1200"></bottom>
	</div>
</template>

<script>
	import top from '@/components/top/index.vue';
	import right from '@/components/right/index.vue';
	import bulkJuhui from './components/pcbBatch/bulkJuhui.vue';
	import pcbBothHave from './components/pcbBatch/pcbBothHave.vue';
	import chooseUs from './components/pcbBatch/chooseUs.vue';
	import certificate from './components/pcbBatch/certificate.vue';
	import aboutUs from './components/pcbBatch/aboutUs.vue';
	import bottom from '@/components/bottom/index.vue';
	export default {
		components:{
			top,
			bulkJuhui,
			pcbBothHave,
			chooseUs,
			certificate,
			aboutUs,
			bottom,
			right
		},
		data() {
			return {
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},
		
		created() {
			
		},
		
		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
</style>