import { render, staticRenderFns } from "./bulkJuhui.vue?vue&type=template&id=20e95bfe&scoped=true&"
import script from "./bulkJuhui.vue?vue&type=script&lang=js&"
export * from "./bulkJuhui.vue?vue&type=script&lang=js&"
import style0 from "./bulkJuhui.vue?vue&type=style&index=0&id=20e95bfe&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20e95bfe",
  null
  
)

export default component.exports