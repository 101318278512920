<template>
	<div>
		<div v-if="windowWidth>=1200">
			<div class="bulkJuhui">
				<div class="bj_titieDiv">
					<div class="bj_td_title">{{$t('pcbBatch.bulkJuhui.title1')}}</div>
					<div class="bj_td_text">{{$t('pcbBatch.bulkJuhui.text1')}}</div>
				</div>
				
				<div class="bj_td_contentTop">
					<div class="bj_td_ct_leftDiv">
						<el-image class="bj_td_ct_ld_img" :src="$t('pcbBatch.bulkJuhui.img')" :fit="'fill'"></el-image>
					</div>
					
					<div class="bj_td_ct_rightDiv">
						<div class="bj_td_ct_rd_titleDiv">
							<div class="bj_td_ct_rd_td_title">{{$t('pcbBatch.bulkJuhui.title2')}}</div>
							<div class="bj_td_ct_rd_td_text">HOT</div>
						</div>
						<div>
							<div class="bj_td_ct_rd_contentDiv">
								<div class="bj_td_ct_rd_cd_label" v-for="(item,index) in $t('pcbBatch.bulkJuhui.list')" :key="index">
									<div class="bj_td_ct_rd_cd_lb_icon">√</div>
									<div class="bj_td_ct_rd_cd_lb_name" :style="item.type==true ? 'color: #d1102d;' : 'color: #626262;'">{{item.name}}</div>
								</div>
								<div class="bj_td_ct_rd_cd_content">{{$t('pcbBatch.bulkJuhui.content')}}</div>
								
							</div>
						</div>
					</div>
				</div>
				
				<div class="bj_td_contentCenter">
					<div class="bj_td_cc_div">
						<div class="bj_td_cc_di_titleDiv">
							<div class="bj_td_cc_di_td_title">{{$t('pcbBatch.bulkJuhui.singlePanel.title')}}</div>
							<div class="bj_td_cc_di_td_label" v-for="(item,index) in $t('pcbBatch.bulkJuhui.singlePanel.label')" :key="index">{{item}}</div>
						</div>
						<div class="bj_td_cc_di_contentDiv">
							<div class="bj_td_cc_di_cd_left">
								<div class="bj_td_cc_di_cd_dataList" v-for="(item,index) in $t('pcbBatch.bulkJuhui.singlePanel.list')" :key="index">
									<div class="bj_td_cc_di_cd_dl_icon">√</div>
									<div class="bj_td_cc_di_cd_dl_name" :style="item.type==true ? 'color: #d1102d;' : 'color: #626262;'">{{item.name}}</div>
								</div>
							</div>
							<div class="bj_td_cc_di_cd_right">
								<el-image class="bj_td_cc_di_cd_ri_img" :src="$t('pcbBatch.bulkJuhui.singlePanel.img')" :fit="'fill'"></el-image>
							</div>
						</div>
					</div>
					
					<div class="bj_td_cc_div" style="margin-left: 4%;">
						<div class="bj_td_cc_di_titleDiv">
							<div class="bj_td_cc_di_td_title">{{$t('pcbBatch.bulkJuhui.nonFlameRetardant.title')}}</div>
							<div class="bj_td_cc_di_td_label" v-for="(item,index) in $t('pcbBatch.bulkJuhui.nonFlameRetardant.label')" :key="index">{{item}}</div>
						</div>
						<div class="bj_td_cc_di_contentDiv">
							<div class="bj_td_cc_di_cd_left">
								<div class="bj_td_cc_di_cd_dataList2" v-for="(item,index) in $t('pcbBatch.bulkJuhui.nonFlameRetardant.list')" :key="index">
									<div class="bj_td_cc_di_cd_dl2_icon">√</div>
									<div class="bj_td_cc_di_cd_dl2_name" :style="item.type==true ? 'color: #d1102d;' : 'color: #626262;'">{{item.name}}</div>
								</div>
							</div>
							<div class="bj_td_cc_di_cd_right">
								<el-image class="bj_td_cc_di_cd_ri_img" :src="$t('pcbBatch.bulkJuhui.nonFlameRetardant.img')" :fit="'fill'"></el-image>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bulkJuhuiBottom">
				<div class="bjb_contentDiv">
					<div class="bjb_cd_left">
						<el-image class="bjb_cd_le_img" :src="$t('pcbBatch.bulkJuhui.fourLayerBoard.img')" :fit="'fill'"></el-image>
					</div>
					
					<div class="bjb_cd_right">
						<div class="bjb_cd_ri_titleDiv">
							<div class="bjb_td_ri_td_title">{{$t('pcbBatch.bulkJuhui.fourLayerBoard.title')}}</div>
							<div class="bjb_td_ri_td_text">{{$t('pcbBatch.bulkJuhui.fourLayerBoard.label')}}</div>
						</div>
						<div>
							<div class="bjb_cd_ri_contentDiv">
								<div class="bjb_cd_ri_cd_dataList" v-for="(item,index) in $t('pcbBatch.bulkJuhui.fourLayerBoard.list')" :key="index">
									<div class="bjb_cd_ri_cd_dl_icon">√</div>
									<div class="bjb_cd_ri_cd_dl_name" :style="item.type==true ? 'color: #d1102d;' : 'color: #626262;'">{{item.name}}</div>
								</div>
								<div class="bjb_cd_ri_cd_content">{{$t('pcbBatch.bulkJuhui.fourLayerBoard.content')}}</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div v-if="windowWidth<=1199">
			<div class="bulkJuhui2">
				<div class="bj_titieDiv">
					<div class="bj_td_title">{{$t('pcbBatch.bulkJuhui.title1')}}</div>
					<div class="bj_td_text">{{$t('pcbBatch.bulkJuhui.text1')}}</div>
				</div>
				
				<div class="bj_td_contentTop">
					<div class="bj_td_ct_titleDiv">
						<div class="bj_td_rd_td_title">{{$t('pcbBatch.bulkJuhui.title2')}}</div>
						<div class="bj_td_rd_td_text">HOT</div>
					</div>
					<div class="bj_td_ct_leftDiv">
						<el-image class="bj_td_ct_ld_img" :src="$t('pcbBatch.bulkJuhui.img')" :fit="'fill'"></el-image>
					</div>
					
					<div class="bj_td_ct_rightDiv">
						<div>
							<div class="bj_td_ct_rd_contentDiv">
								<div class="bj_td_ct_rd_cd_label" v-for="(item,index) in $t('pcbBatch.bulkJuhui.list')" :key="index">
									<div class="bj_td_ct_rd_cd_lb_icon">√</div>
									<div class="bj_td_ct_rd_cd_lb_name" :style="item.type==true ? 'color: #d1102d;' : 'color: #626262;'">{{item.name}}</div>
								</div>
								<div class="bj_td_ct_rd_cd_content">{{$t('pcbBatch.bulkJuhui.content')}}</div>
								
								<!-- <div style="display: flex;justify-content: center;position: relative;width: 100%;">
									<div class="bj_td_ct_rd_cd_priceDiv">
										<div class="bj_td_ct_rd_cd_pd_title">{{$t('pcbBatch.bulkJuhui.priceTitle')}}</div>
										<div class="bj_td_ct_rd_cd_pd_text">{{$t('pcbBatch.bulkJuhui.price')}}</div>
									</div>
								</div> -->
							</div>
						</div>
					</div>
				</div>
				
				<div class="bj_td_contentCenter">
					<div class="bj_td_cc_div">
						<div class="bj_td_cc_di_titleDiv">
							<div class="bj_td_cc_di_td_title">{{$t('pcbBatch.bulkJuhui.singlePanel.title')}}</div>
							<div class="bj_td_cc_di_td_label" v-for="(item,index) in $t('pcbBatch.bulkJuhui.singlePanel.label')" :key="index">{{item}}</div>
						</div>
						<div class="bj_td_cc_di_contentDiv">
							<div class="bj_td_cc_di_cd_left">
								<div class="bj_td_cc_di_cd_dataList" v-for="(item,index) in $t('pcbBatch.bulkJuhui.singlePanel.list')" :key="index">
									<div class="bj_td_cc_di_cd_dl_icon">√</div>
									<div class="bj_td_cc_di_cd_dl_name" :style="item.type==true ? 'color: #d1102d;' : 'color: #626262;'">{{item.name}}</div>
								</div>
							</div>
							<div class="bj_td_cc_di_cd_right">
								<el-image class="bj_td_cc_di_cd_ri_img" :src="$t('pcbBatch.bulkJuhui.singlePanel.img')" :fit="'fill'"></el-image>
							</div>
						</div>
					</div>
					
					<div class="bj_td_cc_div" style="margin-top: 20px;">
						<div class="bj_td_cc_di_titleDiv">
							<div class="bj_td_cc_di_td_title">{{$t('pcbBatch.bulkJuhui.nonFlameRetardant.title')}}</div>
							<div class="bj_td_cc_di_td_label" v-for="(item,index) in $t('pcbBatch.bulkJuhui.nonFlameRetardant.label')" :key="index">{{item}}</div>
						</div>
						<div class="bj_td_cc_di_contentDiv">
							<div class="bj_td_cc_di_cd_left">
								<div class="bj_td_cc_di_cd_dataList2" v-for="(item,index) in $t('pcbBatch.bulkJuhui.nonFlameRetardant.list')" :key="index">
									<div class="bj_td_cc_di_cd_dl2_icon">√</div>
									<div class="bj_td_cc_di_cd_dl2_name" :style="item.type==true ? 'color: #d1102d;' : 'color: #626262;'">{{item.name}}</div>
								</div>
							</div>
							<div class="bj_td_cc_di_cd_right">
								<el-image class="bj_td_cc_di_cd_ri_img" :src="$t('pcbBatch.bulkJuhui.nonFlameRetardant.img')" :fit="'fill'"></el-image>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bulkJuhuiBottom2">
				<div class="bjb_contentDiv">
					<div class="bjb_cd_titleDiv">
						<div class="bjb_td_td_title">{{$t('pcbBatch.bulkJuhui.fourLayerBoard.title')}}</div>
						<div class="bjb_td_td_text">{{$t('pcbBatch.bulkJuhui.fourLayerBoard.label')}}</div>
					</div>
					<div class="bjb_cd_left">
						<el-image class="bjb_cd_le_img" :src="$t('pcbBatch.bulkJuhui.fourLayerBoard.img')" :fit="'fill'"></el-image>
					</div>
					
					<div class="bjb_cd_right">
						<div>
							<div class="bjb_cd_ri_contentDiv">
								<div class="bjb_cd_ri_cd_dataList" v-for="(item,index) in $t('pcbBatch.bulkJuhui.fourLayerBoard.list')" :key="index">
									<div class="bjb_cd_ri_cd_dl_icon">√</div>
									<div class="bjb_cd_ri_cd_dl_name" :style="item.type==true ? 'color: #d1102d;' : 'color: #626262;'">{{item.name}}</div>
								</div>
								<div class="bjb_cd_ri_cd_content">{{$t('pcbBatch.bulkJuhui.fourLayerBoard.content')}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				DoubleSidedBoard:{
					list:[{
						name:"板厚:0.8—1.6mm",
						type:false
					},
					{
						name:"孔径:>0.3mm(12mil)",
						type:false
					},
					{
						name:"江浙沪皖赣粤6省包邮",
						type:true
					},
					{
						name:"孔密度:8万/m²内",
						type:false
					},
					{
						name:"大批量需求可以询价",
						type:true
					},
					{
						name:"线宽线距: 6/6mil",
						type:false
					}],
					content:'PS:应用于低电压低电流的玩具板、普通CTI家用电器板及P4-LED灯板。适用插件孔径孔距大于1.20mm的线路，低电流低电压要求的儿童玩具、家用遥控嚣等要求较低的快速消费产品。',
					priceTitle:'20m²起',
					price:'248元/m²起'
				},
				singlePanel:{
					title:'单面板',
					lable:['板材·国纪A01','板厚· 1.0——1.6mm'],
					list:[{
						name:'丝印冲模',
						type:false
					},
					{
						name:'OSP',
						type:false
					},
					{
						name:'绿油白字',
						type:false
					},
					{
						name:'免测试费',
						type:true
					},
					{
						name:'100m²以上起定',
						type:true
					}],
					price:'140元/m²起'
				},
				nonFlameRetardant:{
					title:'非阻燃双面板',
					lable:['板厚· 0.6——1.6mm'],
					list:[{
						name:'有铅喷锡',
						type:false
					},
					{
						name:'沉铜工艺',
						type:false
					},
					{
						name:'全连线LDI',
						type:false
					},
					{
						name:'全线VCP电镀',
						type:true
					}],
					price1:'190元/m²起',
					price2:'170元/m²起'
				},
				fourLayerBoard:{
					list:[{
						name:"板厚:0.8—1.6mm",
						type:false
					},
					{
						name:"孔径:>0.3mm(12mil)",
						type:false
					},
					{
						name:"1OZ",
						type:false
					},
					{
						name:"孔密度:8万以内",
						type:false
					},
					{
						name:"阻焊：绿油白字",
						type:false
					},
					{
						name:"线宽线距: 5/5mil",
						type:false
					},
					{
						name:"5m²以上起定",
						type:true
					}],
					content:'PS:广泛应用于计算机及外围设备、通讯设备、消费电子、汽车电子及工业应用领域等。',
					price:'498元/m²起'
				}
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.bulkJuhui{
			padding: 40px 200px;
			
			.bj_titieDiv{
				text-align: center;
				
				.bj_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.bj_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.bj_td_contentTop{
				width: 100%;
				border-left: 4px solid #e7e7e7;
				border-bottom: 4px solid #e7e7e7;
				border-right: 4px solid #e7e7e7;
				border-top: 4px solid #d1102d;
				padding: 0 0 1vw 1vw;
				position: relative;
				width: 100%;
				display: flex;
				
				.bj_td_ct_leftDiv{
					width: 40%;
					
					.bj_td_ct_ld_img{
						width: 100%;
						padding-top: 40px;
						padding-bottom: 40px;
					}
				}
				
				.bj_td_ct_rightDiv{
					width: 60%;
					padding-left: 3vw;
					
					.bj_td_ct_rd_titleDiv{
						width: 100%;
						display: flex;
						background: #d1102d;
						border-bottom-left-radius: 50px;
						padding: 1vw 3vw;
						align-items: center;
						
						.bj_td_ct_rd_td_title{
							color: #fff;
							font-size: 2vw;
						}
						
						.bj_td_ct_rd_td_text{
							color: #fff;
							border: 1px solid #fff;
							padding: 2px 6px;
							border-radius: 20px;
							margin-left: 20px;
						}
					}
					
					.bj_td_ct_rd_contentDiv{
						display: flex;
						flex-wrap: wrap;
						padding: 1vw;
						width: 100%;
						
						.bj_td_ct_rd_cd_label{
							display: flex;
							width: 50%;
							align-items: center;
							padding-top: 1vw;
							
							.bj_td_ct_rd_cd_lb_icon{
								color: #d1102d;
								border: 1px solid #d1102d;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 2px;
							}
							
							.bj_td_ct_rd_cd_lb_name{
								padding-left: 10px;
							}
						}
						
						.bj_td_ct_rd_cd_content{
							padding-top: 1vw;
							color: #626262;
							line-height:30px;
						}
						
						.bj_td_ct_rd_cd_priceDiv{
							border: 1px solid #d1102d;
							border-radius: 10px;
							
							.bj_td_ct_rd_cd_pd_title{
								padding: 0.5vw 2vw;
								background: #d1102d;
								border-bottom-left-radius: 20px;
								border-bottom-right-radius: 20px;
								text-align: center;
								color: #fff;
								width: 100%;
								font-size: 1.2vw;
								border-top-left-radius: 10px;
								border-top-right-radius: 10px;
							}
							
							.bj_td_ct_rd_cd_pd_text{
								text-align: center;
								width: 100%;
								padding: 0.5vw;
								font-size: 2vw;
								color: #d1102d;
							}
						}
					}
				}
			}
			
			.bj_td_contentCenter{
				padding-top: 40px;
				display: flex;
				width: 100%;
				justify-items: center;
				
				.bj_td_cc_div{
					width: 48%;
					background: #f6f6f6;
					
					.bj_td_cc_di_titleDiv{
						padding-top: 1vw;
						display: flex;
						align-items: center;
						
						.bj_td_cc_di_td_title{
							padding: 0.4vw 2vw 0.4vw 4vw;
							background: #424242;
							color: #fff;
							font-size: 1vw;
							border-top-right-radius: 40px;
							border-bottom-right-radius: 40px;
							margin-right: 1vw;
						}
						
						.bj_td_cc_di_td_label{
							color: #d1102d;
							border: 1px dashed #d1102d;
							padding: 4px 10px;border-radius: 20px;margin-right: 1vw;font-size: 0.8vw;
						}
					}
					
					.bj_td_cc_di_contentDiv{
						padding: 1vw;
						width: 100%;
						display: flex;
						justify-content: center;
						
						.bj_td_cc_di_cd_left{
							width: 40%;
							
							.bj_td_cc_di_cd_dataList{
								display: flex;
								padding-top: 1vw;
								
								.bj_td_cc_di_cd_dl_icon{
									color: #d1102d;
									border: 1px solid #d1102d;
									padding: 1px 5px;
									border-radius: 40px;
									font-size: 2px;
									height: 22px;
								}
								
								.bj_td_cc_di_cd_dl_name{
									padding-left: 10px;
								}
							}
							
							.bj_td_cc_di_cd_dataList2{
								display: flex;
								padding-top: 0.5vw;
								
								.bj_td_cc_di_cd_dl2_icon{
									color: #d1102d;
									border: 1px solid #d1102d;
									padding: 1px 5px;
									border-radius: 40px;
									font-size: 2px;
									height: 22px;
								}
								
								.bj_td_cc_di_cd_dl2_name{
									padding-left: 10px;
								}
							}
							
							.bj_td_cc_di_cd_priceDiv1{
								color: #d1102d;
								font-size: 1vw;
								padding: 0.5vw 1vw;
								border-radius: 10px;
								border: 1px solid #d1102d;
								margin-top: 2vw;
								text-align: center;
							}
							
							.bj_td_cc_di_cd_priceDiv2{
								font-size: 1vw;
								border-radius: 10px;
								border: 1px solid #d1102d;
								margin-top: 1vw;
								text-align: center;
								
								.bj_td_cc_di_cd_pd2_title{
									padding: 0.5vw 1vw;
								}
								
								.bj_td_cc_di_cd_pd2_text{
									color: #fff;
									background: #d1102d;
									padding: 0.5vw 1vw;
									border-bottom-left-radius: 10px;
									border-bottom-right-radius: 10px;
								}
							}
						}
						
						.bj_td_cc_di_cd_right{
							width: 60%;
							margin-left: 2vw;
							
							.bj_td_cc_di_cd_ri_img{
								width: 100%;
								margin-right: 2%;
							}
						}
					}
				}
			}
		}
		
		.bulkJuhuiBottom{
			padding: 40px 200px;
			background: #424242;
			
			.bjb_contentDiv{
				width: 100%;
				border-left: 4px solid #e7e7e7;
				border-bottom: 4px solid #e7e7e7;
				border-right: 4px solid #e7e7e7;
				border-top: 4px solid #d1102d;
				padding: 0 0 1vw 1vw;
				position: relative;
				width: 100%;
				display: flex;
				background: #fff;
				
				.bjb_cd_left{
					width: 40%;
					
					.bjb_cd_le_img{
						width: 100%;
						padding-top: 40px;
						padding-bottom: 40px;
					}
				}
				
				.bjb_cd_right{
					width: 60%;
					padding-left: 3vw;
					
					.bjb_cd_ri_titleDiv{
						width: 100%;
						display: flex;
						background: #d1102d;
						border-bottom-left-radius: 50px;
						padding: 1vw 3vw;
						align-items: center;
						
						.bjb_td_ri_td_title{
							color: #fff;
							font-size: 2vw;
						}
						
						.bjb_td_ri_td_text{
							color: #fff;
							border: 1px solid #fff;
							padding: 2px 6px;
							border-radius: 20px;
							margin-left: 20px;
						}
					}
					
					.bjb_cd_ri_contentDiv{
						display: flex;
						flex-wrap: wrap;
						padding: 1vw;
						width: 100%;
						
						.bjb_cd_ri_cd_dataList{
							display: flex;
							width: 50%;
							align-items: center;
							padding-top: 1vw;
							
							.bjb_cd_ri_cd_dl_icon{
								color: #d1102d;
								border: 1px solid #d1102d;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 2px;
							}
							
							.bjb_cd_ri_cd_dl_name{
								padding-left: 10px;
							}
						}
						
						.bjb_cd_ri_cd_content{
							padding-top: 1vw;
							color: #626262;
							line-height:30px;
							width: 100%;
						}
						
						.bjb_cd_ri_cd_priceDiv{
							border: 1px solid #d1102d;
							border-radius: 10px;
							margin-top: 1vw;
							
							.bjb_cd_ri_cd_pd_text{
								text-align: center;
								width: 100%;
								padding: 0.5vw;
								font-size: 2vw;
								color: #d1102d;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.bulkJuhui{
			padding: 40px 200px;
			
			.bj_titieDiv{
				text-align: center;
				
				.bj_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.bj_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.bj_td_contentTop{
				width: 100%;
				border-left: 4px solid #e7e7e7;
				border-bottom: 4px solid #e7e7e7;
				border-right: 4px solid #e7e7e7;
				border-top: 4px solid #d1102d;
				padding: 0 0 1vw 1vw;
				position: relative;
				width: 100%;
				display: flex;
				
				.bj_td_ct_leftDiv{
					width: 40%;
					
					.bj_td_ct_ld_img{
						width: 100%;
						padding-top: 40px;
						padding-bottom: 40px;
					}
				}
				
				.bj_td_ct_rightDiv{
					width: 60%;
					padding-left: 3vw;
					
					.bj_td_ct_rd_titleDiv{
						width: 100%;
						display: flex;
						background: #d1102d;
						border-bottom-left-radius: 50px;
						padding: 1vw 3vw;
						align-items: center;
						
						.bj_td_ct_rd_td_title{
							color: #fff;
							font-size: 2vw;
						}
						
						.bj_td_ct_rd_td_text{
							color: #fff;
							border: 1px solid #fff;
							padding: 2px 6px;
							border-radius: 20px;
							margin-left: 20px;
						}
					}
					
					.bj_td_ct_rd_contentDiv{
						display: flex;
						flex-wrap: wrap;
						padding: 1vw;
						width: 100%;
						
						.bj_td_ct_rd_cd_label{
							display: flex;
							width: 50%;
							align-items: center;
							padding-top: 1vw;
							
							.bj_td_ct_rd_cd_lb_icon{
								color: #d1102d;
								border: 1px solid #d1102d;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 2px;
							}
							
							.bj_td_ct_rd_cd_lb_name{
								padding-left: 10px;
							}
						}
						
						.bj_td_ct_rd_cd_content{
							padding-top: 1vw;
							color: #626262;
							line-height:30px;
						}
						
						.bj_td_ct_rd_cd_priceDiv{
							border: 1px solid #d1102d;
							border-radius: 10px;
							
							.bj_td_ct_rd_cd_pd_title{
								padding: 0.5vw 2vw;
								background: #d1102d;
								border-bottom-left-radius: 20px;
								border-bottom-right-radius: 20px;
								text-align: center;
								color: #fff;
								width: 100%;
								font-size: 1.2vw;
								border-top-left-radius: 10px;
								border-top-right-radius: 10px;
							}
							
							.bj_td_ct_rd_cd_pd_text{
								text-align: center;
								width: 100%;
								padding: 0.5vw;
								font-size: 2vw;
								color: #d1102d;
							}
						}
					}
				}
			}
			
			.bj_td_contentCenter{
				padding-top: 40px;
				display: flex;
				width: 100%;
				justify-items: center;
				
				.bj_td_cc_div{
					width: 48%;
					background: #f6f6f6;
					
					.bj_td_cc_di_titleDiv{
						padding-top: 1vw;
						display: flex;
						align-items: center;
						
						.bj_td_cc_di_td_title{
							padding: 0.4vw 2vw 0.4vw 4vw;
							background: #424242;
							color: #fff;
							font-size: 1vw;
							border-top-right-radius: 40px;
							border-bottom-right-radius: 40px;
							margin-right: 1vw;
						}
						
						.bj_td_cc_di_td_label{
							color: #d1102d;
							border: 1px dashed #d1102d;
							padding: 4px 10px;border-radius: 20px;margin-right: 1vw;font-size: 0.8vw;
						}
					}
					
					.bj_td_cc_di_contentDiv{
						padding: 1vw;
						width: 100%;
						display: flex;
						justify-content: center;
						
						.bj_td_cc_di_cd_left{
							width: 40%;
							
							.bj_td_cc_di_cd_dataList{
								display: flex;
								padding-top: 1vw;
								
								.bj_td_cc_di_cd_dl_icon{
									color: #d1102d;
									border: 1px solid #d1102d;
									padding: 1px 5px;
									border-radius: 40px;
									font-size: 2px;
									height: 22px;
								}
								
								.bj_td_cc_di_cd_dl_name{
									padding-left: 10px;
								}
							}
							
							.bj_td_cc_di_cd_dataList2{
								display: flex;
								padding-top: 0.5vw;
								
								.bj_td_cc_di_cd_dl2_icon{
									color: #d1102d;
									border: 1px solid #d1102d;
									padding: 1px 5px;
									border-radius: 40px;
									font-size: 2px;
									height: 22px;
								}
								
								.bj_td_cc_di_cd_dl2_name{
									padding-left: 10px;
								}
							}
							
							.bj_td_cc_di_cd_priceDiv1{
								color: #d1102d;
								font-size: 1vw;
								padding: 0.5vw 1vw;
								border-radius: 10px;
								border: 1px solid #d1102d;
								margin-top: 2vw;
								text-align: center;
							}
							
							.bj_td_cc_di_cd_priceDiv2{
								font-size: 1vw;
								border-radius: 10px;
								border: 1px solid #d1102d;
								margin-top: 1vw;
								text-align: center;
								
								.bj_td_cc_di_cd_pd2_title{
									padding: 0.5vw 1vw;
								}
								
								.bj_td_cc_di_cd_pd2_text{
									color: #fff;
									background: #d1102d;
									padding: 0.5vw 1vw;
									border-bottom-left-radius: 10px;
									border-bottom-right-radius: 10px;
								}
							}
						}
						
						.bj_td_cc_di_cd_right{
							width: 60%;
							margin-left: 2vw;
							
							.bj_td_cc_di_cd_ri_img{
								width: 100%;
								margin-right: 2%;
							}
						}
					}
				}
			}
		}
		
		.bulkJuhuiBottom{
			padding: 40px 200px;
			background: #424242;
			
			.bjb_contentDiv{
				width: 100%;
				border-left: 4px solid #e7e7e7;
				border-bottom: 4px solid #e7e7e7;
				border-right: 4px solid #e7e7e7;
				border-top: 4px solid #d1102d;
				padding: 0 0 1vw 1vw;
				position: relative;
				width: 100%;
				display: flex;
				background: #fff;
				
				.bjb_cd_left{
					width: 40%;
					
					.bjb_cd_le_img{
						width: 100%;
						padding-top: 40px;
						padding-bottom: 40px;
					}
				}
				
				.bjb_cd_right{
					width: 60%;
					padding-left: 3vw;
					
					.bjb_cd_ri_titleDiv{
						width: 100%;
						display: flex;
						background: #d1102d;
						border-bottom-left-radius: 50px;
						padding: 1vw 3vw;
						align-items: center;
						
						.bjb_td_ri_td_title{
							color: #fff;
							font-size: 2vw;
						}
						
						.bjb_td_ri_td_text{
							color: #fff;
							border: 1px solid #fff;
							padding: 2px 6px;
							border-radius: 20px;
							margin-left: 20px;
						}
					}
					
					.bjb_cd_ri_contentDiv{
						display: flex;
						flex-wrap: wrap;
						padding: 1vw;
						width: 100%;
						
						.bjb_cd_ri_cd_dataList{
							display: flex;
							width: 50%;
							align-items: center;
							padding-top: 1vw;
							
							.bjb_cd_ri_cd_dl_icon{
								color: #d1102d;
								border: 1px solid #d1102d;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 2px;
							}
							
							.bjb_cd_ri_cd_dl_name{
								padding-left: 10px;
							}
						}
						
						.bjb_cd_ri_cd_content{
							padding-top: 1vw;
							color: #626262;
							line-height:30px;
							width: 100%;
						}
						
						.bjb_cd_ri_cd_priceDiv{
							border: 1px solid #d1102d;
							border-radius: 10px;
							margin-top: 1vw;
							
							.bjb_cd_ri_cd_pd_text{
								text-align: center;
								width: 100%;
								padding: 0.5vw;
								font-size: 2vw;
								color: #d1102d;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.bulkJuhui{
			padding: 40px 140px;
			
			.bj_titieDiv{
				text-align: center;
				
				.bj_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.bj_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.bj_td_contentTop{
				width: 100%;
				border-left: 4px solid #e7e7e7;
				border-bottom: 4px solid #e7e7e7;
				border-right: 4px solid #e7e7e7;
				border-top: 4px solid #d1102d;
				padding: 0 0 1vw 1vw;
				position: relative;
				width: 100%;
				display: flex;
				
				.bj_td_ct_leftDiv{
					width: 40%;
					
					.bj_td_ct_ld_img{
						width: 100%;
						padding-top: 40px;
						padding-bottom: 40px;
					}
				}
				
				.bj_td_ct_rightDiv{
					width: 60%;
					padding-left: 3vw;
					
					.bj_td_ct_rd_titleDiv{
						width: 100%;
						display: flex;
						background: #d1102d;
						border-bottom-left-radius: 50px;
						padding: 1vw 3vw;
						align-items: center;
						
						.bj_td_ct_rd_td_title{
							color: #fff;
							font-size: 2vw;
						}
						
						.bj_td_ct_rd_td_text{
							color: #fff;
							border: 1px solid #fff;
							padding: 2px 6px;
							border-radius: 20px;
							margin-left: 20px;
						}
					}
					
					.bj_td_ct_rd_contentDiv{
						display: flex;
						flex-wrap: wrap;
						padding: 1vw;
						width: 100%;
						
						.bj_td_ct_rd_cd_label{
							display: flex;
							width: 50%;
							align-items: center;
							padding-top: 1vw;
							
							.bj_td_ct_rd_cd_lb_icon{
								color: #d1102d;
								border: 1px solid #d1102d;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 2px;
							}
							
							.bj_td_ct_rd_cd_lb_name{
								padding-left: 10px;
							}
						}
						
						.bj_td_ct_rd_cd_content{
							padding-top: 1vw;
							color: #626262;
							line-height:30px;
						}
						
						.bj_td_ct_rd_cd_priceDiv{
							border: 1px solid #d1102d;
							border-radius: 10px;
							
							.bj_td_ct_rd_cd_pd_title{
								padding: 0.5vw 2vw;
								background: #d1102d;
								border-bottom-left-radius: 20px;
								border-bottom-right-radius: 20px;
								text-align: center;
								color: #fff;
								width: 100%;
								font-size: 1.2vw;
								border-top-left-radius: 10px;
								border-top-right-radius: 10px;
							}
							
							.bj_td_ct_rd_cd_pd_text{
								text-align: center;
								width: 100%;
								padding: 0.5vw;
								font-size: 2vw;
								color: #d1102d;
							}
						}
					}
				}
			}
			
			.bj_td_contentCenter{
				padding-top: 40px;
				display: flex;
				width: 100%;
				justify-items: center;
				
				.bj_td_cc_div{
					width: 48%;
					background: #f6f6f6;
					
					.bj_td_cc_di_titleDiv{
						padding-top: 1vw;
						display: flex;
						align-items: center;
						
						.bj_td_cc_di_td_title{
							padding: 0.4vw 2vw 0.4vw 4vw;
							background: #424242;
							color: #fff;
							font-size: 1vw;
							border-top-right-radius: 40px;
							border-bottom-right-radius: 40px;
							margin-right: 1vw;
						}
						
						.bj_td_cc_di_td_label{
							color: #d1102d;
							border: 1px dashed #d1102d;
							padding: 4px 10px;border-radius: 20px;margin-right: 1vw;font-size: 0.8vw;
						}
					}
					
					.bj_td_cc_di_contentDiv{
						padding: 1vw;
						width: 100%;
						display: flex;
						justify-content: center;
						
						.bj_td_cc_di_cd_left{
							width: 40%;
							
							.bj_td_cc_di_cd_dataList{
								display: flex;
								padding-top: 1vw;
								
								.bj_td_cc_di_cd_dl_icon{
									color: #d1102d;
									border: 1px solid #d1102d;
									padding: 1px 5px;
									border-radius: 40px;
									font-size: 2px;
									height: 22px;
								}
								
								.bj_td_cc_di_cd_dl_name{
									padding-left: 10px;
								}
							}
							
							.bj_td_cc_di_cd_dataList2{
								display: flex;
								padding-top: 0.5vw;
								
								.bj_td_cc_di_cd_dl2_icon{
									color: #d1102d;
									border: 1px solid #d1102d;
									padding: 1px 5px;
									border-radius: 40px;
									font-size: 2px;
									height: 22px;
								}
								
								.bj_td_cc_di_cd_dl2_name{
									padding-left: 10px;
								}
							}
							
							.bj_td_cc_di_cd_priceDiv1{
								color: #d1102d;
								font-size: 1vw;
								padding: 0.5vw 1vw;
								border-radius: 10px;
								border: 1px solid #d1102d;
								margin-top: 2vw;
								text-align: center;
							}
							
							.bj_td_cc_di_cd_priceDiv2{
								font-size: 1vw;
								border-radius: 10px;
								border: 1px solid #d1102d;
								margin-top: 1vw;
								text-align: center;
								
								.bj_td_cc_di_cd_pd2_title{
									padding: 0.5vw 1vw;
								}
								
								.bj_td_cc_di_cd_pd2_text{
									color: #fff;
									background: #d1102d;
									padding: 0.5vw 1vw;
									border-bottom-left-radius: 10px;
									border-bottom-right-radius: 10px;
								}
							}
						}
						
						.bj_td_cc_di_cd_right{
							width: 60%;
							margin-left: 2vw;
							
							.bj_td_cc_di_cd_ri_img{
								width: 100%;
								margin-right: 2%;
							}
						}
					}
				}
			}
		}
		
		.bulkJuhuiBottom{
			padding: 40px 140px;
			background: #424242;
			
			.bjb_contentDiv{
				width: 100%;
				border-left: 4px solid #e7e7e7;
				border-bottom: 4px solid #e7e7e7;
				border-right: 4px solid #e7e7e7;
				border-top: 4px solid #d1102d;
				padding: 0 0 1vw 1vw;
				position: relative;
				width: 100%;
				display: flex;
				background: #fff;
				
				.bjb_cd_left{
					width: 40%;
					
					.bjb_cd_le_img{
						width: 100%;
						padding-top: 40px;
						padding-bottom: 40px;
					}
				}
				
				.bjb_cd_right{
					width: 60%;
					padding-left: 3vw;
					
					.bjb_cd_ri_titleDiv{
						width: 100%;
						display: flex;
						background: #d1102d;
						border-bottom-left-radius: 50px;
						padding: 1vw 3vw;
						align-items: center;
						
						.bjb_td_ri_td_title{
							color: #fff;
							font-size: 2vw;
						}
						
						.bjb_td_ri_td_text{
							color: #fff;
							border: 1px solid #fff;
							padding: 2px 6px;
							border-radius: 20px;
							margin-left: 20px;
						}
					}
					
					.bjb_cd_ri_contentDiv{
						display: flex;
						flex-wrap: wrap;
						padding: 1vw;
						width: 100%;
						
						.bjb_cd_ri_cd_dataList{
							display: flex;
							width: 50%;
							align-items: center;
							padding-top: 1vw;
							
							.bjb_cd_ri_cd_dl_icon{
								color: #d1102d;
								border: 1px solid #d1102d;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 2px;
							}
							
							.bjb_cd_ri_cd_dl_name{
								padding-left: 10px;
							}
						}
						
						.bjb_cd_ri_cd_content{
							padding-top: 1vw;
							color: #626262;
							line-height:30px;
							width: 100%;
						}
						
						.bjb_cd_ri_cd_priceDiv{
							border: 1px solid #d1102d;
							border-radius: 10px;
							margin-top: 1vw;
							
							.bjb_cd_ri_cd_pd_text{
								text-align: center;
								width: 100%;
								padding: 0.5vw;
								font-size: 2vw;
								color: #d1102d;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.bulkJuhui{
			padding: 40px 30px;
			
			.bj_titieDiv{
				text-align: center;
				
				.bj_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.bj_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.bj_td_contentTop{
				width: 100%;
				border-left: 4px solid #e7e7e7;
				border-bottom: 4px solid #e7e7e7;
				border-right: 4px solid #e7e7e7;
				border-top: 4px solid #d1102d;
				padding: 0 0 1vw 1vw;
				position: relative;
				width: 100%;
				display: flex;
				
				.bj_td_ct_leftDiv{
					width: 40%;
					
					.bj_td_ct_ld_img{
						width: 100%;
						padding-top: 40px;
						padding-bottom: 40px;
					}
				}
				
				.bj_td_ct_rightDiv{
					width: 60%;
					padding-left: 3vw;
					
					.bj_td_ct_rd_titleDiv{
						width: 100%;
						display: flex;
						background: #d1102d;
						border-bottom-left-radius: 50px;
						padding: 1vw 3vw;
						align-items: center;
						
						.bj_td_ct_rd_td_title{
							color: #fff;
							font-size: 2vw;
						}
						
						.bj_td_ct_rd_td_text{
							color: #fff;
							border: 1px solid #fff;
							padding: 2px 6px;
							border-radius: 20px;
							margin-left: 20px;
						}
					}
					
					.bj_td_ct_rd_contentDiv{
						display: flex;
						flex-wrap: wrap;
						padding: 1vw;
						width: 100%;
						
						.bj_td_ct_rd_cd_label{
							display: flex;
							width: 50%;
							align-items: center;
							padding-top: 1vw;
							
							.bj_td_ct_rd_cd_lb_icon{
								color: #d1102d;
								border: 1px solid #d1102d;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 2px;
							}
							
							.bj_td_ct_rd_cd_lb_name{
								padding-left: 10px;
							}
						}
						
						.bj_td_ct_rd_cd_content{
							padding-top: 1vw;
							color: #626262;
							line-height:30px;
						}
						
						.bj_td_ct_rd_cd_priceDiv{
							border: 1px solid #d1102d;
							border-radius: 10px;
							
							.bj_td_ct_rd_cd_pd_title{
								padding: 0.5vw 2vw;
								background: #d1102d;
								border-bottom-left-radius: 20px;
								border-bottom-right-radius: 20px;
								text-align: center;
								color: #fff;
								width: 100%;
								font-size: 1.2vw;
								border-top-left-radius: 10px;
								border-top-right-radius: 10px;
							}
							
							.bj_td_ct_rd_cd_pd_text{
								text-align: center;
								width: 100%;
								padding: 0.5vw;
								font-size: 2vw;
								color: #d1102d;
							}
						}
					}
				}
			}
			
			.bj_td_contentCenter{
				padding-top: 40px;
				display: flex;
				width: 100%;
				justify-items: center;
				
				.bj_td_cc_div{
					width: 48%;
					background: #f6f6f6;
					
					.bj_td_cc_di_titleDiv{
						padding-top: 1vw;
						display: flex;
						align-items: center;
						
						.bj_td_cc_di_td_title{
							padding: 0.4vw 2vw 0.4vw 4vw;
							background: #424242;
							color: #fff;
							font-size: 1vw;
							border-top-right-radius: 40px;
							border-bottom-right-radius: 40px;
							margin-right: 1vw;
						}
						
						.bj_td_cc_di_td_label{
							color: #d1102d;
							border: 1px dashed #d1102d;
							padding: 4px 10px;border-radius: 20px;margin-right: 1vw;font-size: 0.8vw;
						}
					}
					
					.bj_td_cc_di_contentDiv{
						padding: 1vw;
						width: 100%;
						display: flex;
						justify-content: center;
						
						.bj_td_cc_di_cd_left{
							width: 40%;
							
							.bj_td_cc_di_cd_dataList{
								display: flex;
								padding-top: 1vw;
								
								.bj_td_cc_di_cd_dl_icon{
									color: #d1102d;
									border: 1px solid #d1102d;
									padding: 1px 5px;
									border-radius: 40px;
									font-size: 2px;
									height: 22px;
								}
								
								.bj_td_cc_di_cd_dl_name{
									padding-left: 10px;
								}
							}
							
							.bj_td_cc_di_cd_dataList2{
								display: flex;
								padding-top: 0.5vw;
								
								.bj_td_cc_di_cd_dl2_icon{
									color: #d1102d;
									border: 1px solid #d1102d;
									padding: 1px 5px;
									border-radius: 40px;
									font-size: 2px;
									height: 22px;
								}
								
								.bj_td_cc_di_cd_dl2_name{
									padding-left: 10px;
								}
							}
							
							.bj_td_cc_di_cd_priceDiv1{
								color: #d1102d;
								font-size: 1vw;
								padding: 0.5vw 1vw;
								border-radius: 10px;
								border: 1px solid #d1102d;
								margin-top: 2vw;
								text-align: center;
							}
							
							.bj_td_cc_di_cd_priceDiv2{
								font-size: 1vw;
								border-radius: 10px;
								border: 1px solid #d1102d;
								margin-top: 1vw;
								text-align: center;
								
								.bj_td_cc_di_cd_pd2_title{
									padding: 0.5vw 1vw;
								}
								
								.bj_td_cc_di_cd_pd2_text{
									color: #fff;
									background: #d1102d;
									padding: 0.5vw 1vw;
									border-bottom-left-radius: 10px;
									border-bottom-right-radius: 10px;
								}
							}
						}
						
						.bj_td_cc_di_cd_right{
							width: 60%;
							margin-left: 2vw;
							
							.bj_td_cc_di_cd_ri_img{
								width: 100%;
								margin-right: 2%;
							}
						}
					}
				}
			}
		}
		
		.bulkJuhuiBottom{
			padding: 40px 30px;
			background: #424242;
			
			.bjb_contentDiv{
				width: 100%;
				border-left: 4px solid #e7e7e7;
				border-bottom: 4px solid #e7e7e7;
				border-right: 4px solid #e7e7e7;
				border-top: 4px solid #d1102d;
				padding: 0 0 1vw 1vw;
				position: relative;
				width: 100%;
				display: flex;
				background: #fff;
				
				.bjb_cd_left{
					width: 40%;
					
					.bjb_cd_le_img{
						width: 100%;
						padding-top: 40px;
						padding-bottom: 40px;
					}
				}
				
				.bjb_cd_right{
					width: 60%;
					padding-left: 3vw;
					
					.bjb_cd_ri_titleDiv{
						width: 100%;
						display: flex;
						background: #d1102d;
						border-bottom-left-radius: 50px;
						padding: 1vw 3vw;
						align-items: center;
						
						.bjb_td_ri_td_title{
							color: #fff;
							font-size: 2vw;
						}
						
						.bjb_td_ri_td_text{
							color: #fff;
							border: 1px solid #fff;
							padding: 2px 6px;
							border-radius: 20px;
							margin-left: 20px;
						}
					}
					
					.bjb_cd_ri_contentDiv{
						display: flex;
						flex-wrap: wrap;
						padding: 1vw;
						width: 100%;
						
						.bjb_cd_ri_cd_dataList{
							display: flex;
							width: 50%;
							align-items: center;
							padding-top: 1vw;
							
							.bjb_cd_ri_cd_dl_icon{
								color: #d1102d;
								border: 1px solid #d1102d;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 2px;
							}
							
							.bjb_cd_ri_cd_dl_name{
								padding-left: 10px;
							}
						}
						
						.bjb_cd_ri_cd_content{
							padding-top: 1vw;
							color: #626262;
							line-height:30px;
							width: 100%;
						}
						
						.bjb_cd_ri_cd_priceDiv{
							border: 1px solid #d1102d;
							border-radius: 10px;
							margin-top: 1vw;
							
							.bjb_cd_ri_cd_pd_text{
								text-align: center;
								width: 100%;
								padding: 0.5vw;
								font-size: 2vw;
								color: #d1102d;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.bulkJuhui2{
			padding: 40px 30px;
			margin-top: 60px;
			
			.bj_titieDiv{
				text-align: center;
				
				.bj_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.bj_td_text{
					font-size: 16px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.bj_td_contentTop{
				width: 100%;
				border-left: 4px solid #e7e7e7;
				border-bottom: 4px solid #e7e7e7;
				border-right: 4px solid #e7e7e7;
				border-top: 4px solid #d1102d;
				position: relative;
				margin-top: 20px;
				width: 100%;
				
				.bj_td_ct_titleDiv{
					width: 100%;
					display: flex;
					background: #d1102d;
					padding: 1vw 3vw;
					justify-content: center;
					align-items: center;
					
					.bj_td_rd_td_title{
						color: #fff;
						font-size: 2vw;
					}
					
					.bj_td_rd_td_text{
						color: #fff;
						border: 1px solid #fff;
						padding: 2px 6px;
						border-radius: 20px;
						margin-left: 20px;
					}
				}
				
				.bj_td_ct_leftDiv{
					display: flex;
					justify-content: center;
					
					.bj_td_ct_ld_img{
						width: calc(100vw - 200px);
						padding: 20px 40px;
					}
				}
				
				.bj_td_ct_rightDiv{
					padding-left: 3vw;
					
					.bj_td_ct_rd_contentDiv{
						display: flex;
						flex-wrap: wrap;
						padding: 1vw;
						width: 100%;
						
						.bj_td_ct_rd_cd_label{
							display: flex;
							width: 50%;
							align-items: center;
							padding-top: 1vw;
							
							.bj_td_ct_rd_cd_lb_icon{
								color: #d1102d;
								border: 1px solid #d1102d;
								padding: 1px 6px;
								border-radius: 40px;
								font-size: 2px;
							}
							
							.bj_td_ct_rd_cd_lb_name{
								padding-left: 10px;
							}
						}
						
						.bj_td_ct_rd_cd_content{
							padding-top: 10px;
							color: #626262;
							line-height:30px;
						}
						
						.bj_td_ct_rd_cd_priceDiv{
							border: 1px solid #d1102d;
							border-radius: 10px;
							margin:20px 0;
							
							.bj_td_ct_rd_cd_pd_title{
								padding: 0.5vw 2vw;
								background: #d1102d;
								border-bottom-left-radius: 10px;
								border-bottom-right-radius: 10px;
								text-align: center;
								color: #fff;
								width: 100%;
								font-size: 1.2vw;
								border-top-left-radius: 10px;
								border-top-right-radius: 10px;
							}
							
							.bj_td_ct_rd_cd_pd_text{
								text-align: center;
								width: 100%;
								padding: 0.5vw;
								font-size: 2vw;
								color: #d1102d;
							}
						}
					}
				}
			}
			
			.bj_td_contentCenter{
				padding-top: 40px;
				width: 100%;
				justify-items: center;
				
				.bj_td_cc_div{
					width: 100%;
					background: #f6f6f6;
					
					.bj_td_cc_di_titleDiv{
						padding-top: 1vw;
						display: flex;
						align-items: center;
						
						.bj_td_cc_di_td_title{
							padding: 0.4vw 2vw 0.4vw 4vw;
							background: #424242;
							color: #fff;
							font-size: 1vw;
							border-top-right-radius: 40px;
							border-bottom-right-radius: 40px;
							margin-right: 1vw;
						}
						
						.bj_td_cc_di_td_label{
							color: #d1102d;
							border: 1px dashed #d1102d;
							padding: 4px 10px;border-radius: 20px;margin-right: 1vw;font-size: 0.8vw;
						}
					}
					
					.bj_td_cc_di_contentDiv{
						padding: 1vw;
						width: 100%;
						display: flex;
						justify-content: center;
						
						.bj_td_cc_di_cd_left{
							width: 40%;
							
							.bj_td_cc_di_cd_dataList{
								display: flex;
								padding-top: 1vw;
								
								.bj_td_cc_di_cd_dl_icon{
									color: #d1102d;
									border: 1px solid #d1102d;
									padding: 1px 5px;
									border-radius: 40px;
									font-size: 2px;
									height: 22px;
								}
								
								.bj_td_cc_di_cd_dl_name{
									padding-left: 10px;
								}
							}
							
							.bj_td_cc_di_cd_dataList2{
								display: flex;
								padding-top: 0.5vw;
								
								.bj_td_cc_di_cd_dl2_icon{
									color: #d1102d;
									border: 1px solid #d1102d;
									padding: 1px 5px;
									border-radius: 40px;
									font-size: 2px;
									height: 22px;
								}
								
								.bj_td_cc_di_cd_dl2_name{
									padding-left: 10px;
								}
							}
							
							.bj_td_cc_di_cd_priceDiv1{
								color: #d1102d;
								font-size: 1vw;
								padding: 1vw;
								border-radius: 10px;
								border: 1px solid #d1102d;
								margin-top: 2vw;
								text-align: center;
							}
							
							.bj_td_cc_di_cd_priceDiv2{
								font-size: 1vw;
								border-radius: 10px;
								border: 1px solid #d1102d;
								margin-top: 1vw;
								text-align: center;
								
								.bj_td_cc_di_cd_pd2_title{
									padding: 0.5vw 1vw;
								}
								
								.bj_td_cc_di_cd_pd2_text{
									color: #fff;
									background: #d1102d;
									padding: 0.5vw 1vw;
									border-bottom-left-radius: 10px;
									border-bottom-right-radius: 10px;
								}
							}
						}
						
						.bj_td_cc_di_cd_right{
							width: 60%;
							margin-left: 2vw;
							
							.bj_td_cc_di_cd_ri_img{
								width: 100%;
								margin-right: 2%;
							}
						}
					}
				}
			}
		}
		
		.bulkJuhuiBottom2{
			padding: 40px 30px;
			background: #424242;
			
			.bjb_contentDiv{
				width: 100%;
				border-left: 4px solid #e7e7e7;
				border-bottom: 4px solid #e7e7e7;
				border-right: 4px solid #e7e7e7;
				border-top: 4px solid #d1102d;
				position: relative;
				width: 100%;
				background: #fff;
				
				.bjb_cd_titleDiv{
					justify-content: center;
					width: 100%;
					display: flex;
					background: #d1102d;
					padding: 1vw 3vw;
					align-items: center;
					
					.bjb_td_td_title{
						color: #fff;
						font-size: 2vw;
					}
					
					.bjb_td_td_text{
						color: #fff;
						border: 1px solid #fff;
						padding: 2px 6px;
						border-radius: 20px;
						margin-left: 20px;
					}
				}
				
				.bjb_cd_left{
					display: flex;
					justify-content: center;
					
					.bjb_cd_le_img{
						width: calc(100vw - 400px);
						padding-top: 40px;
						padding-bottom: 40px;
					}
				}
				
				.bjb_cd_right{
					
					.bjb_cd_ri_contentDiv{
						display: flex;
						flex-wrap: wrap;
						padding: 2vw;
						width: 100%;
						
						.bjb_cd_ri_cd_dataList{
							display: flex;
							width: 50%;
							align-items: center;
							padding-top: 1vw;
							
							.bjb_cd_ri_cd_dl_icon{
								color: #d1102d;
								border: 1px solid #d1102d;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 2px;
							}
							
							.bjb_cd_ri_cd_dl_name{
								padding-left: 10px;
							}
						}
						
						.bjb_cd_ri_cd_content{
							padding-top: 1vw;
							color: #626262;
							line-height:30px;
							width: 100%;
						}
						
						.bjb_cd_ri_cd_priceDiv{
							border: 1px solid #d1102d;
							border-radius: 10px;
							margin-top: 1vw;
							
							.bjb_cd_ri_cd_pd_text{
								text-align: center;
								width: 100%;
								padding: 0.5vw;
								font-size: 2vw;
								color: #d1102d;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.bulkJuhui2{
			padding: 40px 30px;
			margin-top: 60px;
			
			.bj_titieDiv{
				text-align: center;
				
				.bj_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.bj_td_text{
					font-size: 16px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.bj_td_contentTop{
				width: 100%;
				border-left: 4px solid #e7e7e7;
				border-bottom: 4px solid #e7e7e7;
				border-right: 4px solid #e7e7e7;
				border-top: 4px solid #d1102d;
				position: relative;
				margin-top: 20px;
				width: 100%;
				
				.bj_td_ct_titleDiv{
					width: 100%;
					display: flex;
					background: #d1102d;
					padding: 1vw 3vw;
					justify-content: center;
					align-items: center;
					
					.bj_td_rd_td_title{
						color: #fff;
						font-size: 2vw;
					}
					
					.bj_td_rd_td_text{
						color: #fff;
						border: 1px solid #fff;
						padding: 2px 6px;
						border-radius: 20px;
						margin-left: 20px;
					}
				}
				
				.bj_td_ct_leftDiv{
					display: flex;
					justify-content: center;
					
					.bj_td_ct_ld_img{
						width: calc(100vw - 200px);
						padding: 20px 40px;
					}
				}
				
				.bj_td_ct_rightDiv{
					padding-left: 3vw;
					
					.bj_td_ct_rd_contentDiv{
						display: flex;
						flex-wrap: wrap;
						padding: 0 10px 10px 10px;
						width: 100%;
						
						.bj_td_ct_rd_cd_label{
							display: flex;
							width: 80%;
							align-items: center;
							padding-top: 10px;
							
							.bj_td_ct_rd_cd_lb_icon{
								color: #d1102d;
								border: 1px solid #d1102d;
								padding: 2px 6px;
								border-radius: 40px;
								font-size: 10px;
							}
							
							.bj_td_ct_rd_cd_lb_name{
								padding-left: 10px;
							}
						}
						
						.bj_td_ct_rd_cd_content{
							padding-top: 10px;
							color: #626262;
							line-height:30px;
						}
						
						.bj_td_ct_rd_cd_priceDiv{
							border: 1px solid #d1102d;
							border-radius: 10px;
							margin:20px 0;
							
							.bj_td_ct_rd_cd_pd_title{
								padding: 0.5vw 2vw;
								background: #d1102d;
								border-bottom-left-radius: 10px;
								border-bottom-right-radius: 10px;
								text-align: center;
								color: #fff;
								width: 100%;
								font-size: 1.2vw;
								border-top-left-radius: 10px;
								border-top-right-radius: 10px;
							}
							
							.bj_td_ct_rd_cd_pd_text{
								text-align: center;
								width: 100%;
								padding: 0.5vw;
								font-size: 2vw;
								color: #d1102d;
							}
						}
					}
				}
			}
			
			.bj_td_contentCenter{
				padding-top: 40px;
				width: 100%;
				justify-items: center;
				
				.bj_td_cc_div{
					width: 100%;
					background: #f6f6f6;
					
					.bj_td_cc_di_titleDiv{
						padding-top: 10px;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						
						.bj_td_cc_di_td_title{
							padding: 5px 20px 5px 30px;
							background: #424242;
							color: #fff;
							font-size: 12px;
							border-top-right-radius: 40px;
							border-bottom-right-radius: 40px;
							margin-bottom: 10px;
						}
						
						.bj_td_cc_di_td_label{
							color: #d1102d;
							border: 1px dashed #d1102d;
							padding: 4px 10px;border-radius: 20px;margin-left: 10px;font-size: 12px;margin-bottom: 10px;
						}
					}
					
					.bj_td_cc_di_contentDiv{
						padding: 1vw;
						width: 100%;
						
						.bj_td_cc_di_cd_left{
							width: 100%;
							padding: 10px;
							
							.bj_td_cc_di_cd_dataList{
								display: flex;
								padding-top: 10px;
								align-items: center;
								
								.bj_td_cc_di_cd_dl_icon{
									color: #d1102d;
									border: 1px solid #d1102d;
									padding: 2px 6px;
									border-radius: 40px;
									font-size: 10px;
								}
								
								.bj_td_cc_di_cd_dl_name{
									padding-left: 10px;
								}
							}
							
							.bj_td_cc_di_cd_dataList2{
								display: flex;
								padding-top: 10px;
								
								.bj_td_cc_di_cd_dl2_icon{
									color: #d1102d;
									border: 1px solid #d1102d;
									padding: 2px 6px;
									border-radius: 40px;
									font-size: 10px;
								}
								
								.bj_td_cc_di_cd_dl2_name{
									padding-left: 10px;
								}
							}
							
							.bj_td_cc_di_cd_priceDiv1{
								color: #d1102d;
								font-size: 1vw;
								padding: 1vw;
								border-radius: 10px;
								border: 1px solid #d1102d;
								margin-top: 2vw;
								text-align: center;
							}
							
							.bj_td_cc_di_cd_priceDiv2{
								font-size: 1vw;
								border-radius: 10px;
								border: 1px solid #d1102d;
								margin-top: 1vw;
								text-align: center;
								
								.bj_td_cc_di_cd_pd2_title{
									padding: 0.5vw 1vw;
								}
								
								.bj_td_cc_di_cd_pd2_text{
									color: #fff;
									background: #d1102d;
									padding: 0.5vw 1vw;
									border-bottom-left-radius: 10px;
									border-bottom-right-radius: 10px;
								}
							}
						}
						
						.bj_td_cc_di_cd_right{
							width: 100%;
							padding: 10px;
							
							.bj_td_cc_di_cd_ri_img{
								width: 100%;
								margin-right: 2%;
							}
						}
					}
				}
			}
		}
		
		.bulkJuhuiBottom2{
			padding: 40px 30px;
			background: #424242;
			
			.bjb_contentDiv{
				width: 100%;
				border-left: 4px solid #e7e7e7;
				border-bottom: 4px solid #e7e7e7;
				border-right: 4px solid #e7e7e7;
				border-top: 4px solid #d1102d;
				position: relative;
				width: 100%;
				background: #fff;
				
				.bjb_cd_titleDiv{
					justify-content: center;
					width: 100%;
					display: flex;
					background: #d1102d;
					padding: 1vw 3vw;
					align-items: center;
					
					.bjb_td_td_title{
						color: #fff;
						font-size: 2vw;
					}
					
					.bjb_td_td_text{
						color: #fff;
						border: 1px solid #fff;
						padding: 2px 6px;
						border-radius: 20px;
						margin-left: 20px;
					}
				}
				
				.bjb_cd_left{
					display: flex;
					justify-content: center;
					
					.bjb_cd_le_img{
						width: calc(100vw - 200px);
						padding-top: 40px;
						padding-bottom: 40px;
					}
				}
				
				.bjb_cd_right{
					
					.bjb_cd_ri_contentDiv{
						display: flex;
						flex-wrap: wrap;
						padding: 10px;
						width: 100%;
						
						.bjb_cd_ri_cd_dataList{
							display: flex;
							width: 100%;
							align-items: center;
							padding-top: 1vw;
							
							.bjb_cd_ri_cd_dl_icon{
								color: #d1102d;
								border: 1px solid #d1102d;
								padding: 2px 6px;
								border-radius: 40px;
								font-size: 10px;
							}
							
							.bjb_cd_ri_cd_dl_name{
								padding-left: 10px;
							}
						}
						
						.bjb_cd_ri_cd_content{
							padding-top: 1vw;
							color: #626262;
							line-height:30px;
							width: 100%;
						}
						
						.bjb_cd_ri_cd_priceDiv{
							border: 1px solid #d1102d;
							border-radius: 10px;
							margin-top: 1vw;
							
							.bjb_cd_ri_cd_pd_text{
								text-align: center;
								width: 100%;
								padding: 0.5vw;
								font-size: 2vw;
								color: #d1102d;
							}
						}
					}
				}
			}
		}
	}
</style>