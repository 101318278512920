<template>
	<div>
		<div class="pcbBothHave" v-if="windowWidth>=1200">
			<div class="pbh_titieDiv">
				<div class="pbh_td_title">{{$t('pcbBatch.pcbBothHave.title')}}</div>
				<div class="pbh_td_text">{{$t('pcbBatch.pcbBothHave.text')}}</div>
			</div>
			
			<div class="pbh_contentDiv">
				<div class="pbh_cd_dataList" v-for="(item,index) in $t('pcbBatch.pcbBothHave.list')" :key="index">
					<el-image class="pbh_cd_dl_img" :src="item.img" :fit="'contain'"></el-image>
					<div class="pbh_cd_dl_content">
						<div class="pbh_cd_dl_ct_title" :style="item.type==true ? 'color: #e64638;' : ''">{{item.title}}</div>
						<div class="pbh_cd_dl_ct_textDiv" v-for="(item2,index2) in item.lable" :key="index2">
							<div class="pbh_cd_dl_ct_td_icon">√</div>
							<div class="pbh_cd_dl_ct_td_text" :style="item2.type==true ? 'color: #e64638;' : 'color: #626262;'">{{item2.name}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="pcbBothHave2" v-if="windowWidth<=1199">
			<div class="pbh_titieDiv">
				<div class="pbh_td_title">{{$t('pcbBatch.pcbBothHave.title')}}</div>
				<div class="pbh_td_text">{{$t('pcbBatch.pcbBothHave.text')}}</div>
			</div>
			
			<div class="pbh_contentDiv">
				<div class="pbh_cd_dataList" v-for="(item,index) in $t('pcbBatch.pcbBothHave.list')" :key="index">
					<div style="display: flex;justify-content: center;">
						<el-image class="pbh_cd_dl_img" :src="item.img" :fit="'contain'"></el-image>
					</div>
					<div class="pbh_cd_dl_content">
						<div class="pbh_cd_dl_ct_title" :style="item.type==true ? 'color: #e64638;' : ''">{{item.title}}</div>
						<div class="pbh_cd_dl_ct_textDiv" v-for="(item2,index2) in item.lable" :key="index2">
							<div class="pbh_cd_dl_ct_td_icon">√</div>
							<div class="pbh_cd_dl_ct_td_text" :style="item2.type==true ? 'color: #e64638;' : 'color: #626262;'">{{item2.name}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.pcbBothHave{
			padding: 40px 200px;
			background: #f6f6f6;
			
			.pbh_titieDiv{
				text-align: center;
				
				.pbh_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.pbh_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.pbh_contentDiv{
				padding-top: 20px;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				
				.pbh_cd_dataList{
					width: 48%;
					background: #fff;
					display: flex;
					padding: 1vw;
					margin-right: 2%;
					margin-bottom: 2%;
					
					.pbh_cd_dl_img{
						width: 40%;
						height: 220px;
					}
					
					.pbh_cd_dl_content{
						padding-left: 1vw;
						
						.pbh_cd_dl_ct_title{
							font-size: 1vw;
							font-weight: bold;
						}
						
						.pbh_cd_dl_ct_textDiv{
							display: flex;
							padding-top: 1vw;
							align-items: center;
							
							.pbh_cd_dl_ct_td_icon{
								color: #e64638;
								border: 1px solid #e64638;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 2px;
								height: 22px;
							}
							
							.pbh_cd_dl_ct_td_text{
								padding-left: 10px;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.pcbBothHave{
			padding: 40px 200px;
			background: #f6f6f6;
			
			.pbh_titieDiv{
				text-align: center;
				
				.pbh_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.pbh_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.pbh_contentDiv{
				padding-top: 20px;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				
				.pbh_cd_dataList{
					width: 48%;
					background: #fff;
					display: flex;
					padding: 1vw;
					margin-right: 2%;
					margin-bottom: 2%;
					
					.pbh_cd_dl_img{
						width: 40%;
						height: 220px;
					}
					
					.pbh_cd_dl_content{
						padding-left: 1vw;
						
						.pbh_cd_dl_ct_title{
							font-size: 1vw;
							font-weight: bold;
						}
						
						.pbh_cd_dl_ct_textDiv{
							display: flex;
							padding-top: 1vw;
							align-items: center;
							
							.pbh_cd_dl_ct_td_icon{
								color: #e64638;
								border: 1px solid #e64638;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 2px;
								height: 22px;
							}
							
							.pbh_cd_dl_ct_td_text{
								padding-left: 10px;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.pcbBothHave{
			padding: 40px 140px;
			background: #f6f6f6;
			
			.pbh_titieDiv{
				text-align: center;
				
				.pbh_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.pbh_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.pbh_contentDiv{
				padding-top: 20px;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				
				.pbh_cd_dataList{
					width: 48%;
					background: #fff;
					display: flex;
					padding: 1vw;
					margin-right: 2%;
					margin-bottom: 2%;
					
					.pbh_cd_dl_img{
						width: 40%;
						height: 220px;
					}
					
					.pbh_cd_dl_content{
						padding-left: 1vw;
						
						.pbh_cd_dl_ct_title{
							font-size: 1vw;
							font-weight: bold;
						}
						
						.pbh_cd_dl_ct_textDiv{
							display: flex;
							padding-top: 1vw;
							align-items: center;
							
							.pbh_cd_dl_ct_td_icon{
								color: #e64638;
								border: 1px solid #e64638;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 2px;
								height: 22px;
							}
							
							.pbh_cd_dl_ct_td_text{
								padding-left: 10px;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.pcbBothHave{
			padding: 40px 30px;
			background: #f6f6f6;
			
			.pbh_titieDiv{
				text-align: center;
				
				.pbh_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.pbh_td_text{
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.pbh_contentDiv{
				padding-top: 20px;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				
				.pbh_cd_dataList{
					width: 48%;
					background: #fff;
					display: flex;
					padding: 1vw;
					margin-right: 2%;
					margin-bottom: 2%;
					
					.pbh_cd_dl_img{
						width: 40%;
						height: 180px;
					}
					
					.pbh_cd_dl_content{
						padding-left: 1vw;
						
						.pbh_cd_dl_ct_title{
							font-size: 1vw;
							font-weight: bold;
						}
						
						.pbh_cd_dl_ct_textDiv{
							display: flex;
							padding-top: 1vw;
							align-items: center;
							
							.pbh_cd_dl_ct_td_icon{
								color: #e64638;
								border: 1px solid #e64638;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 2px;
								height: 22px;
							}
							
							.pbh_cd_dl_ct_td_text{
								padding-left: 10px;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.pcbBothHave2{
			padding: 40px 30px;
			background: #f6f6f6;
			
			.pbh_titieDiv{
				text-align: center;
				
				.pbh_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.pbh_td_text{
					font-size: 16px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.pbh_contentDiv{
				padding-top: 20px;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				
				.pbh_cd_dataList{
					width: 100%;
					background: #fff;
					padding: 1vw;
					margin-right: 2%;
					margin-bottom: 2%;
					
					.pbh_cd_dl_img{
						width: 40%;
						padding: 10px 0;
					}
					
					.pbh_cd_dl_content{
						padding-left: 10px;
						
						.pbh_cd_dl_ct_title{
							font-size: 12px;
							font-weight: bold;
							text-align: center;
							padding-bottom: 10px;
						}
						
						.pbh_cd_dl_ct_textDiv{
							display: flex;
							padding-top: 1vw;
							align-items: center;
							
							.pbh_cd_dl_ct_td_icon{
								color: #e64638;
								border: 1px solid #e64638;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 2px;
								height: 22px;
							}
							
							.pbh_cd_dl_ct_td_text{
								padding-left: 10px;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.pcbBothHave2{
			padding: 40px 30px;
			background: #f6f6f6;
			
			.pbh_titieDiv{
				text-align: center;
				
				.pbh_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.pbh_td_text{
					font-size: 16px;
					color: #626262;
					line-height: 28px;
				}
			}
			
			.pbh_contentDiv{
				padding-top: 20px;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				
				.pbh_cd_dataList{
					width: 100%;
					background: #fff;
					padding: 1vw;
					margin-right: 2%;
					margin-bottom: 2%;
					
					.pbh_cd_dl_img{
						width: 40%;
						padding: 10px 0;
					}
					
					.pbh_cd_dl_content{
						padding-left: 10px;
						
						.pbh_cd_dl_ct_title{
							font-size: 12px;
							font-weight: bold;
							text-align: center;
							padding-bottom: 10px;
						}
						
						.pbh_cd_dl_ct_textDiv{
							display: flex;
							padding-top: 1vw;
							align-items: center;
							
							.pbh_cd_dl_ct_td_icon{
								color: #d1102d;
								border: 1px solid #d1102d;
								padding: 2px 6px;
								border-radius: 40px;
								font-size: 10px;
							}
							
							.pbh_cd_dl_ct_td_text{
								padding-left: 10px;
							}
						}
					}
				}
			}
		}
	}
	
</style>