<template>
	<div>
		<div class="aboutUs">
			<div class="au_titleDiv">
				<div class="au_td_title">{{$t('pcbBatch.aboutUs.title')}}</div>
				<div class="au_td_text">{{$t('pcbBatch.aboutUs.text')}}</div>
			</div>
			<el-image class="au_contentImg" :src="img" :fit="'fill'"></el-image>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/factory-display@img.png',
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{	
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.aboutUs{
			padding: 40px 200px;
			background: #fff;
			
			.au_titleDiv{
				text-align: center;
				
				.au_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.au_td_text{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
				}
			}
			
			.au_contentImg{
				width: 100%;
				margin-top: 40px;
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.aboutUs{
			padding: 40px 200px;
			background: #fff;
			
			.au_titleDiv{
				text-align: center;
				
				.au_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.au_td_text{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
				}
			}
			
			.au_contentImg{
				width: 100%;
				margin-top: 40px;
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.aboutUs{
			padding: 40px 140px;
			background: #fff;
			
			.au_titleDiv{
				text-align: center;
				
				.au_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.au_td_text{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
				}
			}
			
			.au_contentImg{
				width: 100%;
				margin-top: 40px;
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.aboutUs{
			padding: 40px 30px;
			background: #fff;
			
			.au_titleDiv{
				text-align: center;
				
				.au_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.au_td_text{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
				}
			}
			
			.au_contentImg{
				width: 100%;
				margin-top: 40px;
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.aboutUs{
			padding: 40px 30px;
			background: #fff;
			
			.au_titleDiv{
				text-align: center;
				
				.au_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.au_td_text{
					font-size: 16px;
					color: #333333;
					line-height: 28px;
				}
			}
			
			.au_contentImg{
				width: 100%;
				margin-top: 20px;
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.aboutUs{
			padding: 40px 30px;
			background: #fff;
			
			.au_titleDiv{
				text-align: center;
				
				.au_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.au_td_text{
					font-size: 16px;
					color: #333333;
					line-height: 28px;
				}
			}
			
			.au_contentImg{
				width: 100%;
				margin-top: 20px;
			}
		}
	}
	
</style>